import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7c87fd0e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "brand-definition"
};
import BrandDefinitionBanner from "@/views/digitalCustom/brand-definition-module/brand-definition-banner.vue";
import BrandDefinitionContent from "@/views/digitalCustom/brand-definition-module/brand-definition-content.vue";
export default {
  __name: 'brand-high-definition',
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(BrandDefinitionBanner), _createVNode(BrandDefinitionContent)]);
    };
  }
};